import React from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import { Subtitle } from "../../components/atoms/Subtitle/subtitle";
import Button from "../../components/atoms/Button";
import pageNotFound from "../../images/logos/illust_404-passport.svg";
import "./page404.scss";

const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <div className="page404">
          <img
            className="u-marginBottom"
            src={pageNotFound}
            alt="logo"
          />
          <Title
            hero
            tag="h1"
            content="ERROR 404"
          />
          <Subtitle
            big
            content="Sorry, the page you are looking for is not available"
          />
          <Button
            link="/home"
            tertiary
            label={"Go to homepage"}
          />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
